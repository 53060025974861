import { ValidateEmail } from '../../string-validation.js';
import { getProducts, loadProductComponentfunction, products_pagination } from './products.js';
import { SP_getCurrentShoppingCart } from './shopping_cart.js';
/////////////////////////////////////
$(document).on('change', '#menu-search-input', searchProductChange);
$(document).on('click', '#menu-shopping-cart-opt', openShoppingCart);
$(document).on('click', '#responsive-close-opt', closeResponsiveEmergentMenu);
$(document).on('click', '#responsive-ul-nav li', closeResponsiveEmergentMenu);
$(document).on('click', '#burger-menu', openResponsiveEmergentMenu);
$(document).on('click', '#contact-form-submit', sendContactForm);
$(document).on('click', '#nav-opt-login, #menu-shopping-cart-opt-login', openLoginContainer);
$(document).on('click', '#login-container #login-close-button', closeLoginContainer);
$(document).on('click', '#login-container #login-button', loginUser);
$(document).on('click', '#nav-opt-user', goToUser);
$(document).on('click', '#login-forgot-opt', sendForgotPasswordEmail);
$(document).on('click', '#google-login', loginitGoogle);
$(document).on('click', '.mail-container-action', openContactForm);
$(document).on('click', '#close-contact-form', closeContactForm);
$(document).on('click', '#whatsapp-container', openWhatsappForm);
$(document).on('click', '#whatsapp-form-close-button', closeWhatsappForm);
$(document).on('click', '#whatsapp-form-button', contactWhatsApp);
/////////////////////////////////////
var curretUrl = '';
let isHome = true; 
var openShoppingCartListFlag = false;
var hoverTimeout;
var socialMedia = null;
$(document).ready(function(){
    curretUrl = window.location.href;
    //get path from url
    let path = curretUrl.split('/');
    path = path[path.length - 1];  
    isHome = path=='' || path.includes('#'); 
    /////////////////////
    $('#shopping-cart-badge-container').hover(openShoppingCartList, closeShoppingCartList);
    $('#menu-opt-brands').hover(hoverOnMenuBrandOption, leaveOnMenuBrandOption);
    $('#brands-list-menu').hover(persistOnMenuOption, leaveOnMenuBrandOption);
    $('#menu-opt-categories').hover(hoverOnMenuCategoryOption, leaveOnMenuCategoryOption);
    $('#category-container-menu').hover(persistOnMenuOption, leaveOnMenuCategoryOption);
    /////////////////////
    getSocialMedia();
    getCompanyInfo();
    getCompanyAddresses();
    getBrands();
    getLegalDocs();
    getCategories();
    getContactRequirements();
    loadProductComponentfunction();
    SP_getCurrentShoppingCart();
});
function openShoppingCart(){
    location.href = '/shoppingCart';
}
function getSocialMedia(){
    GetMethodFunction('/WebPage/SocialMedia/Get', null, showSocialMedia, null);
}
function hoverOnMenuBrandOption(){
    $('#brands-list-menu').css('display', 'flex');
}
function leaveOnMenuBrandOption(){
    hoverTimeout = setTimeout(function(){
        $('#brands-list-menu').css('display', 'none');
    }, 500);
    
}
function hoverOnMenuCategoryOption(){
    $('#category-container-menu').css('display', 'flex');
}
function leaveOnMenuCategoryOption(){
    hoverTimeout = setTimeout(function(){
        $('#category-container-menu').css('display', 'none');
    }, 500);
}
function persistOnMenuOption(){
    clearTimeout(hoverTimeout);
}
function showSocialMedia(response){
    socialMedia = response.data;
    let AppendContentHeader = '';
    let AppendContentFooter = '';
    $.each(response.data, function(index, item){
        AppendContentHeader += '<li class="menu-social-list-item"><a href="'+item.url+'" target="_blank"><img src="/'+item.path+'" alt="'+item.name+'" class="menu-social-list-item-icon"></a></li>';
        AppendContentFooter += '<li class="footer-social-list-item">';
            AppendContentFooter += '<a href="'+item.url+'" target="_blank">';
                AppendContentFooter += '<img src="/'+item.path+'" alt="'+item.name+'" class="footer-social-list-item-icon">';
            AppendContentFooter += '</a>';
        AppendContentFooter += '</li>';
    });
    $('#menu-social-list').html(AppendContentHeader);
    $('#footer-social-list').html(AppendContentFooter);
}
function getCompanyInfo(){
    GetMethodFunction('/WebPage/Company/Info/GetCurrent', null, showCompanyInfo, null);
}
function showCompanyInfo(response){
    let data = response.data;
    //about us
    $('#about-us-text-action').attr('href', 'tel:'+data.phone);
    $('#about-us-list-item-mission .about-us-paragraph').html(data.mission.replace(/\n/g, "<br>"));
    $('#about-us-list-item-vision .about-us-paragraph').html(data.vision.replace(/\n/g, "<br>"));
    $('#about-us-list-item-values .about-us-paragraph').html(data.values.replace(/\n/g, "<br>"));
    $('#about-us-list-item-trajectory .about-us-paragraph').html(data.trajectory.replace(/\n/g, "<br>"));
    $('#company-description').html(data.description.replace(/\n/g, "<br>"));
    //contact
    $('#contact-schedule').html(data.schedule.replace(/\n/g, "<br>"));
    initMap(Number(data.lat), Number(data.lng));
}
function initMap(latitud, longitud) {
    // The location of Uluru
    var uluru = {lat: latitud, lng: longitud+0.004};
    var pointer = {lat: latitud, lng: longitud};
    // The map, centered at Uluru
    var map = new google.maps.Map(
        document.getElementById('contact-map-container'), {zoom: 16, center: uluru}
    );
    // The marker, positioned at Uluru
    var marker = new google.maps.Marker({position: pointer, map: map});
}
function getCompanyAddresses(){
    GetMethodFunction('/WebPage/Company/Address/Get', null, showCompanyAddresses, null);
}
function showCompanyAddresses(response){
    let addresses = response.data;
    let AppendContent = '';
    $.each(addresses, function(index, item){
        AppendContent += '<div class="info-container">';
            AppendContent += '<div class="title-container">';
                AppendContent += '<i class="icon fa-solid fa-location-dot"></i>';
                AppendContent += '<h5 class="title">'+item.name+'</h5>';
            AppendContent += '</div>';
            AppendContent += '<div class="text-container">';
                AppendContent += '<p class="info">'+item.address+'</p>';
            AppendContent += '</div>';
            AppendContent += '<div class="extra-information-container">';
                AppendContent += '<div class="extra-information-sub-container d-flex align-content-start justify-content-start">';
                    AppendContent += '<i class="extra-information-icon fas fa-phone-alt"></i>';
                    AppendContent += '<ul class="extra-information-list">';
                        $.each(item.phones, function(index, phone){
                            AppendContent += '<li class="extra-information-list-item">';
                                AppendContent += '<a href="tel:'+phone.phone+'" class="extra-information-list-item-text">'+phone.phone+'</a>';
                            AppendContent += '</li>';
                        _});
                    AppendContent += '</ul>';
                AppendContent += '</div>';
                AppendContent += '<div class="extra-information-sub-container d-flex align-content-start justify-content-start">';
                    AppendContent += '<i class="extra-information-icon fa-solid fa-envelope"></i>';
                    AppendContent += '<ul class="extra-information-list">';
                        $.each(item.emails, function(index, email){
                            AppendContent += '<li class="extra-information-list-item">';
                                AppendContent += '<a href="mailto:'+email.email+'" class="extra-information-list-item-text">'+email.email+'</a>';
                            AppendContent += '</li>';
                        _});
                    AppendContent += '</ul>';
                AppendContent += '</div>';
            AppendContent += '</div>';
        AppendContent += '</div>';
    });
    $('#contact-addresses-container').empty().append(AppendContent);
   
}
function getBrands(){
    GetMethodFunction('/WebPage/Brand/GetAll', null, showBrands, null);
}
function showBrands(response){
    let LandingAppendContent = '';
    let MenuAppendContent = '';
    let FooterAppendContent = '';
    $.each(response.brands, function(index, item){
        //Header Content
        MenuAppendContent += '<li class="brands-list-menu- align-self-center" onclick="location.href=\'/brand/'+item.url+'\'"><img src="'+item.path+'" alt="brand-1" class="brand-img-menu"></li>';
        //Footer Content
        FooterAppendContent += '<li class="footer-list-item footer-list-item-secondary"><span>'+item.name+'</span></li>';
        if(isHome){
            //Landing Content
            LandingAppendContent += '<li class="brands-list-item d-flex flex-column">';
                LandingAppendContent += '<img src="'+item.path+'" alt="brand-1" class="brand-img">';
                LandingAppendContent += '<h3 class="brand-title">'+item.name+'</h3>';
                LandingAppendContent += '<p class="brand-paragraph" title="'+item.description+'">'+item.description+'</p>';
                LandingAppendContent += '<button class="brand-button mt-auto mb-0" onclick="location.href=\'/brand/'+item.url+'\'">Conocer más</button>';
                //LandingAppendContent += '<button class="brand-button" onclick="location.href=\'/#contact-form-container\'">Conocer más</button>';
            LandingAppendContent += ' </li>';
        }
    });
    if(isHome) $('#brands-list').html(LandingAppendContent);
    $('#brands-list-menu').html(MenuAppendContent);
    $('#footer-brand-list .footer-list-item-secondary').remove();
    $('#footer-brand-list').append(FooterAppendContent);
}
function getCategories(){
    GetMethodFunction('/WebPage/Category/GetAll', null, showCategories, null);
}
function showCategories(response){
    let HeaderAppendContent = '';
    let LandingAppendContent = '';
    let FooterAppendContent = '';
    $.each(response.data, function(index, item){
        //Header Content
        HeaderAppendContent += '<li class="menu-category-list-item" onclick="location.href=\'/Categories?category='+item.url+'\'">'+item.name+'</li>';
        //Footer Content
        FooterAppendContent += '<li class="footer-list-item footer-list-item-secondary" onclick="location.href=\'/Categories?category='+item.url+'\'"><span>'+item.name+'</span></li>';
        if(isHome){
            //Landing Content
            LandingAppendContent += '<li class="category-list-item">';
                LandingAppendContent += '<img src="'+item.path+'" alt="category-1" class="category-img">';
                LandingAppendContent += '<h6 class="category-title">'+item.name+'</h6>';
                LandingAppendContent += '<button class="category-button" onclick="location.href=\'/Categories?category='+item.url+'\'">Ver</button>';
                //LandingAppendContent += '<button class="category-button" onclick="location.href=\'/#contact-form-container\'">Ver</button>';
            LandingAppendContent += '</li>';
        }
    });
    if(isHome) $('#category-list').html(LandingAppendContent);
    $('#category-list-menu').html(HeaderAppendContent);
    $('#footer-categories-list .footer-list-item-secondary').remove();
    $('#footer-categories-list').append(FooterAppendContent);
}
function closeResponsiveEmergentMenu(){
    $('#responsive-menu').animate({
        left: '-100%'
    }, 500, function(){
        $('#responsive-menu').css('display', 'none');
    });
}
function openResponsiveEmergentMenu(){
    $('#responsive-menu').css('display', 'block').css('left', '-100%');
    $('#responsive-menu').animate({
        left: '0%'
    }, 500);
}
function getLegalDocs(){
    GetMethodFunction('/WebPage/Company/LegalDoc/Get', null, showLegalDocs, null);
}
function showLegalDocs(response){
    let AppendContent = '';
    $.each(response.data, function(index, item){
        AppendContent += '<li class="footer-list-item footer-list-item-title"><a href="/WebPage/Company/LegalDoc/Download/'+item.id+'" target="_blank">'+item.name+'</a></li>';
    });
    $('#footer-legal-docs-list .footer-list-item').remove();
    $('#footer-legal-docs-list').append(AppendContent);
}
function getContactRequirements(){
    GetMethodFunction('/WebPage/Company/ContactRequirement/Get', null, showContactRequirements, null);
}
function showContactRequirements(response){
    let AppendContent = '';
    $.each(response.data, function(index, item){
        AppendContent += '<option value="'+item.id+'">'+item.name+'</option>';
    });
    $('#contact-form-client-type').append(AppendContent);
}
function sendContactForm(){
    let flag = true;
    let name = $('#contact-form-name').val();
    let company = $('#contact-form-company').val();
    let phone = $('#contact-form-phone').val();
    let email = $('#contact-form-email').val();
    let client_type = $('#contact-form-client-type').val();
    let subject = $('#contact-form-subject').val();
    let message = $('#contact-form-message').val();
    //sanitize inputs
    name = sanitizeInput(name);
    company = sanitizeInput(company);
    phone = sanitizeInput(phone);
    email = sanitizeInput(email);
    message = sanitizeInput(message);
    
    if(name == ''){
        alertWarning('Debe ingresar su nombre');
        flag = false;
    }else if(name.length > 50){
        alertWarning('Debe ingresar un nombre válido, con menos de 50 caracteres');
        flag = false;
    }
    if(company == ''){
        alertWarning('Debe ingresar su empresa');
        flag = false;
    }else if(company.length > 50){
        alertWarning('Debe ingresar una empresa válida, con menos de 50 caracteres');
        flag = false;
    }
    if(phone == ''){
        alertWarning('Debe ingresar su teléfono');
        flag = false;
    }else if(phone.length > 20){
        alertWarning('Debe ingresar un teléfono válido, con menos de 20 caracteres');
        flag = false;
    }
    if(email == '' || !ValidateEmail(email)){
        alertWarning('Debe ingresar un correo electrónico válido');
        flag = false;
    }else if(email.length > 100){
        alertWarning('Debe ingresar un correo electrónico válido, con menos de 100 caracteres');
        flag = false;
    }
    if(subject == null || subject == '0'){
        flag = false;
        alertWarning('El campo asunto es obligatorio');
    }
    if(message == null || message == ''){
        flag = false;
        alertWarning('El campo mensaje es obligatorio');
    }else if(message.length > 500){
        flag = false;
        alertWarning('El mensaje no puede tener más de 500 caracteres');
    }
    if(flag){
        let contactSubmitButton = $('#contact-form-submit');
        //disable contactSubmitButton until response
        contactSubmitButton.attr('disabled', true);
        contactSubmitButton.text('Enviando...');
        let data = {
            name: name
            , company: company
            , phone: phone
            , email: email
            , client_type: client_type
            , subject: subject
            , message: message
        };
        PostMethodFunction('/WebPage/Contact/SendContactEmail', data, null,  function(){
            contactSubmitButton.attr('disabled', false);
            contactSubmitButton.text('Enviar');
            $('#contact-form-name').val('');
            $('#contact-form-company').val('');
            $('#contact-form-phone').val('');
            $('#contact-form-email').val('');
            $('#contact-form-client-type').val('0');
            $('#contact-form-subject').val('0');
            $('#contact-form-message').val('');
            swallMessage(
                'Mensaje enviado'	
                , 'Estaremos en contacto contigo lo más pronto posible'
                , 'success'
                , null
                , null
                , 3000
                , null
                , null
            );
            closeContactForm();
            gtagSendEvent();
        }, function(){
            contactSubmitButton.attr('disabled', false);
            contactSubmitButton.text('Enviar');
        });
    }
}
function searchProductChange(){
    let search = $(this).val();
    //get current url
    let currentUrl = window.location.href;
    if(currentUrl.includes('/products') && !currentUrl.includes('/products/')){
        //update url parameter without reload
        products_pagination.page = 1;
        getProducts();
    }else{
        window.location.href = '/products?search='+search;
    }
    
}
function openLoginContainer(){
    $('#login-container #identification-login-input').val('');
    $('#login-container #password-login-input').val('');
    $('#login-container').fadeIn(300, function(){
        $('#login-container #identification-login-input').focus();
    });
}
function closeLoginContainer(){
    $('#login-container').fadeOut(300);
}
function loginUser(){
    let identification = $('#login-container #identification-login-input').val();
    let password = $('#login-container #password-login-input').val();
    let flag = true;
    if(identification == ''){
        alertWarning('Debe ingresar su identificación');
        flag = false;
    }
    if(password == ''){
        alertWarning('Debe ingresar su contraseña');
        flag = false;
    }
    if(flag){
        $('#login-container #login-button').attr('disabled', true).text( 'Loading...');
        let data = {
            identification: identification,
            password: password
        };
        PostMethodFunction('/User/Login', data, null, loginResponseSuccess, function(){
            $('#login-container #login-button').attr('disabled', false).text( 'Login');
        });
    }
}
function loginResponseSuccess(result){
    $('#login-container #login-button').attr('disabled', false).text( 'Login');
    location.href = '/products';
}
function goToUser(){
    location.href = '/User';
}
function sendForgotPasswordEmail(){
    let identification = $('#login-container #identification-login-input').val();
    if(identification == ''){
        alertWarning('Debe ingresar su identificación/correo electrónico');
    }else{
        closeLoginContainer();
        let data = {
            identification: identification
        };
        PostMethodFunction('/User/ForgotPassword', data, null, forgotPasswordResponseSuccess, function(){
            openLoginContainer();
        });
    }
}
function forgotPasswordResponseSuccess(result){
    $('#login-container #login-forgot-opt').attr('disabled', false);
    swallMessage(
        'Correo enviado'	
        , 'Se ha enviado un correo electrónico con las instrucciones para recuperar su contraseña'
        , 'success'
        , null
        , null
        , 3000
        , null
        , null
    );
}
function openShoppingCartList(){
    if(!openShoppingCartListFlag && $('#shopping-cart-list .shopping-cart-list-item').length > 0){
        openShoppingCartListFlag = true;
        $('#shopping-cart-list').slideDown(300);
    }
}
function closeShoppingCartList(){
    $('#shopping-cart-list').slideUp(300);
    setTimeout(() => {
        openShoppingCartListFlag = false;
    }, 500);
}
function loginitGoogle(){
	location.href = '/User/Google/auth';
}
function openContactForm(){
    $('#contact-container').addClass('opened');
}
function closeContactForm(){
    $('#contact-container').removeClass('opened');
}
function openWhatsappForm(){
    //location.href = '/contact-whatsapp';
    $('#whatsapp-form-data').removeClass('d-none').addClass('d-flex');
    //initialize form
    $('#whatsapp-form-name-input').val('');
    $('#whatsapp-form-email-input').val('');
    $('#whatsapp-form-message-input').val('');
    $('#whatsapp-form-name-input').focus();
}
function closeWhatsappForm(){
    $('#whatsapp-form-data').removeClass('d-flex').addClass('d-none');
}
function sanitizeInput(input) {
    return input
        .replace(/[<>]/g, "") // Elimina etiquetas HTML
        .replace(/[^\w\s.,!?@-]/gi, "") // Elimina emojis y caracteres especiales no permitidos
        .trim(); // Elimina espacios innecesarios
}

function contactWhatsApp() {
    let flag = true;
    let name = sanitizeInput($('#whatsapp-form-name-input').val());
    let email = sanitizeInput($('#whatsapp-form-email-input').val());
    let message = sanitizeInput($('#whatsapp-form-message-input').val());

    if (!name) {
        flag = false;
        alertWarning('Ingresa tu nombre');
    }else if (name.length > 50) {
        flag = false;
        alertWarning('Ingresa un nombre válido, con menos de 50 caracteres');
    }
    if (!email || !ValidateEmail(email) || email.length > 100) {
        flag = false;
        alertWarning('Ingresa un correo electrónico válido');
    }
    if(message.length > 500){
        flag = false;
        alertWarning('El mensaje no puede tener más de 500 caracteres');
    }
    if (flag) {
        if (socialMedia) {
            let whatsapp = socialMedia.find(x => x.name.toLowerCase() === 'whatsapp');
            if (whatsapp) {
                let button = $('#whatsapp-form-button');
                button.attr('disabled', true).text('Enviando...');
                let data = { url: whatsapp.url, name, email, message };

                PostMethodFunction('/WebPage/Contact/SendWhatsappEmail', data, null, function () {
                    button.attr('disabled', false).text('Enviar');
                    swallMessage('Mensaje enviado', 'Estaremos en contacto contigo lo más pronto posible', 'success', null, null, 3000, null, null);
                    contact-container();
                }, null);

                if (message) {
                    console.log('message', message);
                    whatsapp.url += '?text=' + encodeURIComponent(message);
                }
                window.open(whatsapp.url, '_blank');
            } else {
                location.href = 'https://wa.me/+573164178802';
            }
        }
    }
}
