var shoppingCartProdutctList = [];
var productQuantityFlag = false;
function SP_addProductToShoppingCart(product_id, quantity, size_id, color_id, completeFunctionCallBack = null){
  $('#shopping-cart-badge-container').addClass('added-to-cart');
  let DataSend = {
    'product_id': product_id
    ,'quantity': quantity
    ,'size_id': size_id
    ,'color_id': color_id
  }
  PostMethodFunction('/ShoppingCart/AddProduct', DataSend, null , function(response){
    if(completeFunctionCallBack!=null){
      $('#shopping-cart-badge-container').removeClass('added-to-cart');
      completeFunctionCallBack(response);
      SP_getCurrentShoppingCart();
    }}, function(){
      if(completeFunctionCallBack!=null){
        $('#shopping-cart-badge-container').removeClass('added-to-cart');
        completeFunctionCallBack(null);
      }
    });
}
function SP_getCurrentShoppingCart(completeFunctionCallBack = null){
  $('#shopping-cart-list').empty();
  GetMethodFunction('/ShoppingCart/GetCurrentShoppingCart', null, 
  function(response){
    if(response.data == null || response.data.products_quantity == 0){
      if(window.location.href.indexOf('/shoppingCart')==-1){
        $('#shopping-cart-badge').hide();
      }else{
        location.href = '/products';
      }
      return;  
    }else{
      $('#shopping-cart-badge').show();
    }
    $('#menu-shopping-cart-opt').addClass('fa-bounce');
      setTimeout(() => {
        $('#menu-shopping-cart-opt').removeClass('fa-bounce');
      }, 5000);
    $('#shopping-cart-badge').text(response.data.products_quantity<99?response.data.products_quantity:'+');
    shoppingCartProdutctList = response.data.products;
    let AppendContent = '';
    let counter = 0;
    $.each(shoppingCartProdutctList, function(index, product){
      try{
        AppendContent += '<li class="shopping-cart-list-item'+((counter<shoppingCartProdutctList.length-1)?' button-border':'')+'" title="'+product.web_product.name+'" value="'+product.id+'">';
          AppendContent += '<div class="shopping-cart-list-image-container" style="background-image:url(\'/'+(product.web_product.front_images.length>0?product.web_product.front_images[0].path:'')+'\');"></div>';
          AppendContent += '<p class="shopping-cart-list-name"><strong>'+product.web_product.name+'</strong>';
          if(product.size!=null)  AppendContent += '<br><small>x '+product.size.size+'</small>';
          if(product.color!=null) AppendContent += '<br><small>Color: '+product.color.color+'</small>';
          AppendContent += '<br><small><strong>$'+product.total.toLocaleString().replace(',', '.')+' COP</strong></small>';
          AppendContent += '</p>';
          AppendContent += '<div class="shopping-cart-list-quantity input-group d-inline-flex align-items-center justify-content-around">';
            AppendContent += '<i class="fa-solid fa-chevron-down"></i>';
            AppendContent += '<input type="number" class="product-quantity-input form-control text-center" value="'+product.quantity+'" min="1">';
            AppendContent += '<i class="fa-solid fa-chevron-up"></i>';
          AppendContent += '</div>';
          AppendContent += '<button class="product-remove-button" value="'+product.id+'">X</button>';
        AppendContent += '</li>';
        counter++;
      }catch(e){
        console.log(e);
      }
    });
    $('#shopping-cart-list').html(AppendContent);
    let currentUrl = window.location.href;
    /*if(shoppingCartProdutctList.length>0){
      if(currentUrl.indexOf('/ShoppingCart')==-1 && $(window).width()>=768){
        $('#shopping-cart-list').slideDown(500);
      }
    }*/
    if(currentUrl.indexOf('/shoppingCart')!=-1){
      //Set products on shopping cart table page
      let AppendContent = '';
      let AppendBriefContent = '';
      let counter = 0;
      let total = 0;
      let currentQuanity = 0;
      let currentUrl = window.location.href;
      productQuantityFlag = true;
      $.each(shoppingCartProdutctList, function(index, product){
        currentQuanity = product.size == null?0:product.size.quantity;
        //Principal list
        AppendContent += '<tr class="shopping-cart-list-item " title="'+product.web_product.name+'" value="'+product.id+'">';
          AppendContent += '<td class="product-item-image text-center">';
            AppendContent += '<div class="shopping-cart-table-image-container" style="background-image:url(\'/'+(product.web_product.front_images.length>0?product.web_product.front_images[0].path:'')+'\');"></div>';
          AppendContent += '</td>';
          AppendContent += '<td class="product-item-name text-left">'+product.web_product.name+'</td>';
          AppendContent += '<td class="product-item-size text-center">'+(product.size!=null?product.size.size:'')+'</td>';
          //AppendContent += '<td class="product-item-color text-center">'+(product.color!=null?product.color.color:'')+'</td>';
          AppendContent += '<td class="product-item-sub-total text-end">';
            AppendContent += '$'+product.sub_total.toLocaleString().replace(',', '.')+' COP';
          AppendContent += '</td>';
          AppendContent += '<td class="product-item-quantity-container text-center">';
            if(currentQuanity<product.quantity){
              productQuantityFlag = false;
              if(currentQuanity<=0){
                AppendContent += '<p class="stock-alert text-danger m-0 p-0">Out of stock</p>';
              }else{
                AppendContent += '<p class="stock-alert text-danger m-0 p-0">Only '+currentQuanity+' left!</p>';
              }
            }
            if(currentQuanity>0){
              AppendContent += '<div class="product-item-quantity input-group d-inline-flex align-items-center justify-content-around">';
                AppendContent += '<i class="fa-solid fa-chevron-down"></i>';
                AppendContent += '<input type="number" class="product-quantity-input form-control text-center" value="'+product.quantity+'" min="1">';
                AppendContent += '<i class="fa-solid fa-chevron-up"></i>';
              AppendContent += '</div>';
            }
          AppendContent += '</td>';
          AppendContent += '<td class="product-item-total text-end">';
            if(product.discount>0) AppendContent += '<small class="text-success">-'+product.discount+'%</small>&nbsp;';
            AppendContent += '$'+product.total.toLocaleString().replace(',', '.')+' COP';
          AppendContent += '</td>';
          AppendContent += '<td class="product-item-remove text-center">';
            AppendContent += '<button class="product-remove-button" value="'+product.id+'">X</button>';
          AppendContent += '</td>';
        AppendContent += '</tr>';
        //Brief list
        AppendBriefContent += '<li class="list-group-item brief-products-list-item d-flex justify-content-between">';
            AppendBriefContent += '<div class="brief-products-list-item-container align-self-center">';
                AppendBriefContent += '<p class="brief-products-list-name">('+product.quantity+') '+product.web_product.name+'</p>';
                if(product.color!=null) AppendBriefContent += '<p class="brief-products-list-color">Color: '+product.color.color+'</p>';
                if(product.size!=null) AppendBriefContent += '<p class="brief-products-list-size">x '+product.size.size+'</p>';
            AppendBriefContent += '</div>';
            AppendBriefContent += '<p class="brief-products-list-total align-self-center">$'+product.total+' COP</p>';
        AppendBriefContent += '</li>';
        total += product.total;
        counter++;
      });
      $('#products-table tbody').empty().html(AppendContent);
      $('#brief-products-list').empty().html(AppendBriefContent);
      $('#total-text').text('Total $'+total.toLocaleString()+' COP');
    }
    if(completeFunctionCallBack!=null){
      completeFunctionCallBack(response);
    }}, function(){if(completeFunctionCallBack!=null){completeFunctionCallBack(null);}});
}
function SP_removeProductFromShoppingCart(shopping_cart_product_id, completeFunctionCallBack = null){
  let DataSend = {
    'shopping_cart_product_id': shopping_cart_product_id
  }
  PostMethodFunction('/ShoppingCart/RemoveProduct', DataSend, null , function(response){
    SP_getCurrentShoppingCart();
    if(completeFunctionCallBack!=null){
      completeFunctionCallBack(response);
    }
  }, function(){
      if(completeFunctionCallBack!=null){
        completeFunctionCallBack(null);
      }
    });
}
function SP_updateProductQuantity(shopping_cart_product_id, quantity, completeFunctionCallBack = null){
  let DataSend = {
    'shopping_cart_product_id': shopping_cart_product_id
    ,'quantity': quantity
  }
  PostMethodFunction('/ShoppingCart/UpdateProductQuantity', DataSend, null , function(response){
    SP_getCurrentShoppingCart();
    if(completeFunctionCallBack!=null){
      completeFunctionCallBack(response);
    }}, function(){
      if(completeFunctionCallBack!=null){
        completeFunctionCallBack(null);
      }
    });
}
export { SP_addProductToShoppingCart, SP_getCurrentShoppingCart, SP_removeProductFromShoppingCart, SP_updateProductQuantity };

