export function ValidateEmail(email) {
	var re = /\S+@\S+\.\S+/;
	return re.test(email);
}
export function ValidateCellPhone(phone) {
	/*10 or more digits*/
	var re = /^\d{10,}$/;
	return re.test(phone);
}
export function ValidateURL(url) {
	/*https://maps.app.goo.gl/seUt18NnHkNWg39F6*/
	var link = /^(http|https):\/\/[^ "]+$/;
	var mailTo = /^mailto:[^ "]+$/;
	return link.test(url) || mailTo.test(url);
}